<template>
  <HxTable v-model:columns="columns" :reload="loadData" :type="1">
    <!-- 页面头部查询模块 -->

    <template #search>
      <a-form layout="inline">
        <a-form-item label="" style="width: 13%">
          <a-date-picker v-model:value="searchForm.startTime" style="width: 100%" @change="startChange" allowClear
            placeholder="创建时间起" />

          <!-- <a-range-picker v-model:value="searchReset.startTime" @change="timeChange" format="YYYY-MM-DD"

                :placeholder="['创建时间起', '创建时间终']" style="width:100%" /> -->
        </a-form-item>

        <a-form-item label="" style="width: 13%">
          <a-date-picker v-model:value="searchForm.endTime" style="width: 100%" @change="endChange" allowClear
            placeholder="创建时间终" />
        </a-form-item>
        <a-form-item label="" style="width: 13%">
          <a-select v-model:value="searchForm.customerSourceType" allowClear :options="$store.state.enumAll.customerSource" placeholder="客户来源">
          </a-select>
        </a-form-item>

<!--        <a-form-item label="" style="width: 13%">-->
<!--          <a-cascader allowClear style="width: 100%" v-model:value="searchReset.ChannelTypeEnum"-->
<!--            :options="$store.state.enumAll.ChannelTypeEnum" :show-search="{ filter }" :changeOnSelect="true"-->
<!--            @change="setChannel" placeholder="渠道来源" />-->
<!--        </a-form-item>-->

        <a-form-item label="" style="width: 13%">
          <a-select v-model:value="searchForm.tag" style="width: 100%" placeholder="客户标签" allowClear>
            <a-select-option v-for="item in $store.state.app.labelAll.CUSTOMER_LABEL" :value="item.value"
              :key="item.value">{{ item.label }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="" style="width: 13%">
          <a-input v-model:value="searchForm.overdue" style="width: 100%"  placeholder="超期未联系" class="ransport_search_input" />
        </a-form-item>

        <a-form-item label="" style="width: 13%">
          <a-input v-model:value="searchForm.mobile" style="width: 100%"  placeholder="客户电话"
            class="ransport_search_input" />
        </a-form-item>

        <a-form-item label="" style="width: 13%">
          <a-input v-model:value="searchForm.customerName" style="width: 100%"  placeholder="客户名称"
            class="ransport_search_input" />
        </a-form-item>

        <a-form-item label="" style="width: 13%">
          <!-- <a-range-picker :show-time="{ format: 'HH:mm' }" format="YYYY-MM-DD HH:mm" :placeholder="['领取时间起', '领取时间终']"

                @change="receiveChange" style="width:100%" /> -->

          <a-date-picker v-model:value="searchForm.receiveStartTime" style="width: 100%" @change="receiveStartChange" allowClear
            placeholder="领取时间起" />
        </a-form-item>

        <a-form-item label="" style="width: 13%">
          <a-date-picker v-model:value="searchForm.receiveEndTime" style="width: 100%" @change="receiveEndChange" allowClear
            placeholder="领取时间终" />
        </a-form-item>

        <a-form-item label="" style="width: 13%">
          <a-select v-model:value="searchForm.clueType" style="width: 100%" placeholder="线索分类"
            :options="$store.state.enumAll.ClueTypeEnum" allowClear>
          </a-select>
        </a-form-item>

        <a-form-item label="" style="width: 13%">
          <a-select v-model:value="searchForm.customerType" :options="$store.state.enumAll.CustomerTypeEnum"
            style="width: 100%" placeholder="客户类型" allowClear>
          </a-select>
        </a-form-item>

        <a-form-item label="" style="width: 13%">
          <a-input v-model:value="searchForm.saleName" style="width: 100%" @blur="saleNameChange" placeholder="业务员" 
            class="ransport_search_input" />
        </a-form-item>

        <a-form-item label="" style="width: 13%">
          <a-input-number style="width: 50%"  v-model:value="searchForm.followStartNum" :min="0" placeholder="跟进次数始" />

          <a-input-number style="width: 50%"  v-model:value="searchForm.followEndNum" :min="0" placeholder="跟进次数末" />
        </a-form-item>

        <a-form-item label="" style="width: 13%">
          <a-input v-model:value="searchForm.followContent" style="width: 100%" placeholder="跟进信息" 
            class="ransport_search_input" />
        </a-form-item>

        <a-form-item label="" style="width: 13%">
          <a-date-picker v-model:value="searchForm.followStartTime" style="width: 100%" @change="followStartTimeChange" allowClear
            placeholder="跟进时间起" />
        </a-form-item>

        <a-form-item label="" style="width: 13%">
          <a-date-picker v-model:value="searchForm.followEndTime" style="width: 100%" @change="followEndTimeChange" allowClear
            placeholder="跟进时间终" />
        </a-form-item>

        <!-- <a-form-item label="" style="width: 13%">
          <a-select :options="$store.state.enumAll.FollowUpEnum" style="width: 100%" allowClear
            v-model:value="searchForm.followLabelId" placeholder="跟进标签">
          </a-select>
        </a-form-item> -->

        <!-- <a-form-item label="" style="width: 13%">
          <a-select v-model:value="searchForm.settlementMode" :options="$store.state.enumAll.CustomerSettlementEnum"
            style="width: 100%" placeholder="结算类型" allowClear>
          </a-select>
        </a-form-item> -->

        <a-form-item label="" style="width: 13%">
          <a-input-number style="width: 50%"  v-model:value="searchForm.offerStartNum" :min="0" placeholder="询价次数始" />

          <a-input-number style="width: 50%"  v-model:value="searchForm.offerEndNum" :min="0" placeholder="询价次数末" />
        </a-form-item>

        <a-form-item label="" style="width: 13%">
          <a-input style="width: 50%" v-model:value="searchForm.clueLineStart"  :min="1" placeholder="线索起点" />

          <a-input style="width: 50%" v-model:value="searchForm.clueLineEnd"  :min="1" placeholder="线索终点" />
        </a-form-item>
        <a-form-item label="" style="width: 13%">
          <a-select v-model:value="searchForm.intentionLevel" allowClear :options="$store.state.app.labelAll.INTENTION_LEVEL_DIS" placeholder="意向等级">
          </a-select>
        </a-form-item>

        <!-- <a-col :span="4" class="search">

              <a-form-item label="是否掉落">

                <a-select allowClear v-model:value="searchForm.drop" class="ransport_search_input" placeholder="请选择" :options="$store.state.enumAll.BooleanFlagEnum">

                </a-select>

              </a-form-item>

            </a-col> -->

        <a-space>
          <a-button  :loading="searchLoading" type="primary" @click="onSearch"
            :disabled="searchLoading">
            查询
          </a-button>

          <a-button @click="reset"> 重置 </a-button>

          <!-- <span @click="searchSeniorShow = true" class="colo_blue cursor-pointer">高级搜索</span> -->

          <!-- <span>已选择<span class="colo_blue cursor-pointer"> {{quantity}} </span>项</span> -->
        </a-space>
      </a-form>

      <!-- 高级搜索 -->

      <a-modal v-model:visible="searchSeniorShow" width="800px" title="高级搜索设置" @ok="onSearchSenior">
        <div class="searchSenior-modal">
          <div class="searchSenior-modal-line">
            <div class="searchSenior-modal-line-label">领取时间：</div>

            <div class="searchSenior-modal-line-value">
              <a-range-picker :show-time="{ format: 'HH:mm' }" format="YYYY-MM-DD HH:mm" :placeholder="['起始时间', '截止时间']"
                @change="receiveChange" style="width: 50%" />
            </div>
          </div>

          <div class="searchSenior-modal-line">
            <div class="searchSenior-modal-line-label">客户类型：</div>

            <div class="searchSenior-modal-line-value">
              <a-radio-group v-model:value="searchForm.customerType" button-style="solid">
                <a-space>
                  <a-radio-button :value="item.value" v-for="item in $store.state.enumAll.CustomerTypeEnum"
                    :key="item.value">{{ item.label }}</a-radio-button>
                </a-space>
              </a-radio-group>
            </div>
          </div>

          <div class="searchSenior-modal-line">
            <div class="searchSenior-modal-line-label">结算类型：</div>

            <div class="searchSenior-modal-line-value">
              <a-radio-group v-model:value="searchForm.settlementMode" button-style="solid">
                <a-space>
                  <a-radio-button :value="index" v-for="(item, index) in $store.state.enumAll.CustomerSettlementEnum"
                    :key="index">{{ item.label }}</a-radio-button>
                </a-space>
              </a-radio-group>
            </div>
          </div>

          <div class="searchSenior-modal-line">
            <div class="searchSenior-modal-line-label">客户标签：</div>

            <div class="searchSenior-modal-line-value">
              <a-radio-group v-model:value="searchForm.tagId" button-style="solid">
                <a-space>
                  <a-radio-button v-for="item in $store.state.enumAll.CustomerLabelType" :key="item.value"
                    :value="item.value">{{ item.label }}</a-radio-button>
                </a-space>
              </a-radio-group>
            </div>
          </div>
        </div>
      </a-modal>
    </template>

    <template #totalbar>
      <ExclamationCircleTwoTone twoToneColor="#427bb1" />
      您私海总数：
      <a-tag color="#1890ff">{{ addTotalNum.privateSeasTotalNum }} </a-tag> 条，已有
      <a-tag color="#1890ff">{{ pagination.total }}</a-tag> 条
    </template>

    <template #toolReload> </template>
    <!-- 上方的左边按钮 -->
    <template #toolbarLeft>
      <a-space>
        <a-button @click="searchAddShow = true" class="tool-color-344563">
          <PlusCircleOutlined class="tool-icon" /> 新增
        </a-button>
        <a-button :disabled="searchReceive" @click="customTransferShow = true" class="tool-color-344563">
          移交
        </a-button>
        <!-- <a-button :disabled="searchReceive" @click="onExport" class="tool-color-344563">
              <icon-font type="hy-lingqu1" class="tool-icon" />
              导出客户
            </a-button> -->
      </a-space>
    </template>
    <!-- 表格主体 -->
    <a-table size="small" :columns="columns" :row-key="(record) => record.customerId" :data-source="listData" bordered
      :pagination="pagination" :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      :loading="searchLoading" @change="handleTableChange">
      <template #data="{ record }">
        <a-popover title="详细信息">
          <template #content>
            <p style="width: 400px; padding: 0 10px">{{ record.follows }}</p>
            <p style="width: 400px; padding: 0 10px">{{ record.clues }}</p>
          </template>
          <div>
            <div style="
                width: 100%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              ">
              {{ record.follows }}
            </div>
            <div style="
                width: 100%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              ">
              {{ record.clues }}
            </div>
          </div>
        </a-popover>
      </template>
      <template #intentionLevel="{ record }">
        <a-tag color="#FFA301" v-if="record.intentionLevel">{{record.intentionLevel?.label}}</a-tag>
      </template>

      <template #mobile="{ record }">
        <div>
          <span>{{ record.mobile }}</span>

          <span v-if="record.mobile !== '' && record.wechat !== ''">/</span>

          <span v-if="record.wechat !== null && record.wechat !== ''">{{
    record.wechat
  }}</span>
        </div>
      </template>

      <template #operation="{ record }">
        <span>
          <a @click="onSee(record)">查看</a>
        </span>
      </template>

      <template #channel="{ record }">
        {{ record.channelLabel
        }}<span v-if="record.channelLabel != null && record.channelSubLabel != null">-{{ record.channelSubLabel
          }}</span>
      </template>

      <template #name="{ record }">
        <span>
          <a @click="onDetail(record)">
            <span v-if="record.name !== ''">{{ record.name }}</span>
            <span v-else> 无</span>
          </a>
        </span>
        <span v-if="record.tagNames">
          <div>
            <a-popover placement="bottom" title="标签">
              <template #content>
                <div style="max-width:500px;padding: 0 5px;"> <a-tag color="#009587" style="margin-bottom:5px"  v-for="item in record.tagNames.split(',')" :key="item">{{item}}</a-tag></div>
              </template>
              <div style=display:flex>
                <div class="title-label" style="color:#F0F2F5;width:600px;overflow:hidden;white-space:nowrap;text-overflow: ellipsis;border-radius: 5px;background-color: #009587;">{{
                    record.tagNames.replaceAll(',',' ') }}
                </div>
              </div>
            </a-popover>
          </div>
<!--        <a-tag color="#383838" v-for="item in record.tagNames.split(',')" :key="item">{{item}}</a-tag>-->
        </span>
      </template>
    </a-table>
  </HxTable>
  <a-drawer title="详情" width="80%" placement="right" v-model:visible="detailShow" destroyOnClose @close="closeDrawer">
    <CustomerDetail v-model:customerId="customerId" type="1" @ok="closeDrawer" @updatePage="loadData" />
  </a-drawer>
  <a-modal v-model:visible="searchAddShow" width="30%" @ok="onAddForm">

    <template #title>
      <span class="title">新增</span>
    </template>
    <a-form layout="vertical" ref="formRef" :model="addForm" :rules="rules">
      <a-form-item>

        <template #label>
          <div><span style="color: #f00">* </span>客户类型</div>
        </template>
        <a-select v-model:value="addForm.customerType.value" @change="customerTypeChange" :options="CustomerTypeEnum"
          placeholder="请选择客户类型">
        </a-select>
      </a-form-item>
      <!-- <a-form-item label="业务类型" v-show="addForm.customerType.value !== 1">
        <a-radio-group name="businessType" v-model:value="addForm.businessType">

          <template v-for="item in businessTypes" :key="item.value">
            <a-radio-button :value="item.value"> {{ item.label }}</a-radio-button>
          </template>
        </a-radio-group>
      </a-form-item> -->
      <a-form-item v-if="addForm.customerType.value === 2" label="企业名称" name="name" :rules="{
    required: true,
    message: '请填写企业名称',
    trigger: 'change',
    type: 'string',
  }">
        <a-select v-model:value="addForm.name" placeholder="请输入企业名称" :filter-option="false" :show-arrow="false"
          @search="entpSearch" @select="entpSelect" show-search>
          <a-select-option v-for="item in enterprises" :key="item.creditCode" :value="item.name">
            <span>{{ item.name }}</span>
            <span style="float: right">{{ item.operName }}</span>
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="客户姓名" name="name" v-if="addForm.customerType.value === 1">
        <a-input v-model:value="addForm.name" placeholder="请填写客户姓名" />
      </a-form-item>
      <a-form-item label="客户来源" name="customerSourceType">
        <div style="display: flex;">
          <a-select allowClear v-model:value="addForm.customerSourceType" :options="$store.state.enumAll.customerSource"
            placeholder="选择客户来源" @change="customerSourceTypeChange">
          </a-select>
          <a-select v-if="addForm.customerSourceType === 2" :filter-option="false" show-search placeholder="请输入员工姓名" @search="employerValue">

            <template v-if="flagLoading" #notFoundContent>
              <a-spin size="small" />
            </template>
            <a-select-option v-for="item in employeeOptions" :key="item.id" :value="item.name"
                             @click="addEmployeeChoose(item)">{{ item.name }}-{{ item.orgName }}</a-select-option>
          </a-select>
          <a-select v-if="addForm.customerSourceType === 3"  placeholder="搜索并选择客户" @search="addEntpSearch" labelInValue :filterOption="false" show-search>

            <template v-if="fetching" #notFoundContent>
              <a-spin size="small" />
            </template>
            <a-select-option v-for="item in enterprises" :key="item.name" :value="item.id"
                             @click="enterpriseChoose(item)">
              {{ item.name }}—{{ item.mobile }}
            </a-select-option>
          </a-select>
          <a-cascader v-if="addForm.customerSourceType === 4"  v-model:value="addForm.addChannels" :options="$store.state.enumAll.ChannelTypeEnum"
                      :show-search="{ filter }" :changeOnSelect="true" placeholder="选择渠道" />
        </div>
      </a-form-item>
      <a-form-item label="客户电话" name="mobile">
        <a-input v-model:value="addForm.mobile" placeholder="请填写客户电话" />
      </a-form-item>
      <a-form-item label="客户微信">
        <a-input v-model:value="addForm.wechat" placeholder="填写客户微信" />
      </a-form-item>
      <a-form-item label="客户地址">
        <a-input v-model:value="addForm.address" placeholder="请填写客户地址" />
      </a-form-item>
      <a-form-item name="certificateNumber">

        <template #label>
          {{ addForm.customerType.value === 2 ? "企业信用代码" : "客户身份证" }}
        </template>
        <a-input v-model:value="addForm.certificateNumber" placeholder="请填写" />
      </a-form-item>
<!--      <a-form-item label="结算方式">-->
<!--        <a-select allowClear v-model:value="addForm.settlementMode" :options="CustomerSettlementEnum"-->
<!--          placeholder="请选择结算方式">-->
<!--        </a-select>-->
<!--      </a-form-item>-->
      <a-form-item label="线索类型">
        <a-select allowClear v-model:value="addForm.clueType" :options="$store.state.enumAll.ClueTypeEnum"
          placeholder="请选择线索类型">
          <a-select-option v-for="item in $store.state.enumAll.ClueTypeEnum" :key="item.value">
            {{ item.label }}</a-select-option>
        </a-select>
      </a-form-item>
      <!-- <a-form-item>
<template #label>
    <div>

        <span>客户标签 </span>

        <a style="font-size:12px" @click="addForm.tagId = ''"> 重置选择</a>

    </div>
</template>
        <a-radio-group v-model:value="addForm.tagId" button-style="solid">
          <a-space>
            <a-radio-button v-for="item in $store.state.enumAll.CustomerType" :key="item.value" :value="item.value">{{
              item.label }}</a-radio-button>
          </a-space>
        </a-radio-group>
      </a-form-item> -->
    </a-form>
  </a-modal>
  <!-- 移交 -->
  <a-modal v-model:visible="customTransferShow" title="客户移交" @ok="confirmTransfer" destroyOnClose  :confirmLoading="transferLoading">
    <div>
      <a-select :filter-option="false" show-search placeholder="请输入员工姓名或关键字,按下回车搜索" @search="employerValue"
        style="width: 100%">

        <template v-if="flagLoading" #notFoundContent>
          <a-spin size="small" />
        </template>
        <a-select-option v-for="item in employeeOptions" :key="item.id" :value="item.name"
          @click="employeeChoose(item)">{{ item.name }}-{{ item.no }}-{{ item.mobile }}</a-select-option>
      </a-select>
    </div>
  </a-modal>
</template>

<script>
import { reactive, toRefs, onMounted, ref } from 'vue'
import HxTable from '@/components/HxTable'
import { ExclamationCircleTwoTone, PlusCircleOutlined } from '@ant-design/icons-vue'
import { page, add } from '@/api/crmManagement/privateSea'
import { employeeList } from '@/api/crmManagement/setRules'
import { pageAll } from '@/api/crm/customer'
import {
  enterpriseValidate,
  idNumberValidate,
  positiveNumberValidate
} from '@/utils/validate'
import { addTotal, transferCustom } from '@/api/crmManagement/tool'
import CustomerDetail from '@/views/crmManagement/tool/customerDetail/index.vue'
import { enterprise } from '@/api/system/setting'
import { useStore } from 'vuex'

import { message } from 'ant-design-vue'
export default {
  components: {
    HxTable,
    ExclamationCircleTwoTone,
    PlusCircleOutlined,
    CustomerDetail
  },
  setup () {
    const store = useStore()
    const formRef = ref()
    const state = reactive({
      customerId: '',
      start: '',
      end: '',
      activeKey: '1',
      addTotalNum: {
        highSeasTotalNum: 0,
        highSeasNum: 0
      },
      searchReset: { ChannelTypeEnum: [] },
      CustomerTypeEnum: [],
      CustomerSettlementEnum: [],
      quantity: 1,
      totalbarNum: 0,
      searchForm: {
        channel: null,
        startTime: '',
        endTime: '',
        receiveStartTime: null,
        receiveEndTime: null,
        followStartTime: null,
        followEndTime: null,
        followStartNum: '',
        followEndNum: '',
        offerStartNum: '',
        offerEndNum: '',
        clueLineStart: undefined,
        clueLineEnd: undefined,
        customerType: null,
        settlementMode: null,
        clueType: null,
        tagId: null,
        intentionLevel: null
      },
      addForm: {
        name: '',
        mobile: '',
        wechat: '',
        customerSourceType: null,
        sourcePersonId: '',
        sourcePerson: '',
        customerType: { value: 1, label: '个人' }
      },
      mirrorAddForm: {},
      transferForm: {
        empAfterName: '',
        empAfterId: ''
      },
      businessTypes: [
        { label: '同行业务', value: 2 },
        { label: '大客户业务', value: 3 },
        { label: '平台业务', value: 4 }
      ],
      customerSource: [],
      selectedRowKeys: [],
      enterprises: [],
      employeeOptions: [], // 员工list
      customTransferShow: false,
      transferLoading: false,
      searchLoading: false,
      customerType: false,
      flagLoading: false,
      searchShow: false,
      searchReceive: true,
      searchSeniorShow: false,
      searchAddShow: false,
      detailShow: false,
      flag1: false,
      flag2: false,
      flag3: false,
      flag4: false,
      timer: null,
      columns: [
        {
          title: '客户名称',
          dataIndex: 'name',
          // width: '12%',
          slots: {
            customRender: 'name'
          }
        },
        {
          title: '客户类型',
          dataIndex: 'customerType.label'
          // width: '6%'
        },
        {
          title: '线索跟进信息',
          width: '18%',
          ellipsis: true,
          slots: {
            customRender: 'data'
          }
        },
        {
          title: '下次跟进时间',
          dataIndex: 'nextFollowTime',
          width: '7%'
        },
        {
          title: '意向等级',
          dataIndex: 'intentionLevel.label',
          slots: {
            customRender: 'intentionLevel'
          }
          // width: '6%'
        },
        {
          title: '客户电话/微信',
          dataIndex: 'mobile',
          width: '7%',
          slots: {
            customRender: 'mobile'
          }
        },
        {
          title: '创建时间',
          dataIndex: 'createTime'
          // width: '8%'
        },
        {
          title: '领取时间',
          dataIndex: 'enterPrivateTime'
          // width: '10%'
        },
        {
          title: '更新时间',
          dataIndex: 'updateTime'
          // width: '10%'
        },
        {
          title: '业务员',
          dataIndex: 'saleName'
          // width: '5%'
        },
        {
          title: '客户来源',
          dataIndex: 'customerSourceType.label'
          // width: '12%',
        },
        {
          title: '线索分类',
          dataIndex: 'clueType.label'
          // width: '5%'
        },
        {
          title: '起点终点',
          dataIndex: 'clues'
          // width: '5%'
        }
        // {
        //   title: "客户标签",
        //   dataIndex: "tagName",
        //   // width: '8%'
        // },
      ],
      rules: {
        name: { required: true, message: '请填写客户名称', trigger: 'blur' },
        customerType: {
          required: true,
          message: '请选择客户类型',
          trigger: 'change',
          type: 'number'
        },
        customerSourceType: {
          required: true,
          message: '请选择客户来源',
          trigger: 'blur',
          type: 'number'
        },
        settlementMode: {
          required: true,
          message: '请选择结算方式',
          trigger: 'change',
          type: 'number'
        },
        certificateNumber: [
          { required: false, message: '请填写身份证号码', trigger: 'blur' },
          {
            pattern: idNumberValidate,
            message: '请填写正确的身份证号码',
            trigger: 'blur'
          }
        ],
        mobile: [
          { required: false, message: '请填写电话号码', trigger: 'blur' },
          {
            pattern: positiveNumberValidate,
            message: '请填写正确的电话号码',
            trigger: 'blur'
          }
        ]
        // clueType: { required: true, message: '请选择线索类型', trigger: 'change', type: 'number' },
        // addChannels: { required: true, message: '请选择渠道来源', trigger: 'change', type: 'array' }
      },
      listData: [],
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      }
    })
    // 重置
    const reset = () => {
      state.end = ''
      state.start = ''
      state.searchForm = {}
      state.searchReset = {}
      loadData()
    }
    // 查询
    const onSearch = () => {
      state.searchLoading = true
      state.pagination.current = 1
      loadData()
    }
    // 高级-时间
    const receiveChange = (e, v) => {
      state.searchForm.receiveStartTime = v[0]
      state.searchForm.receiveEndTime = v[1]
    }
    // 高级搜索方法
    const onSearchSenior = () => {
      state.searchLoading = true
      state.pagination.current = 1
      loadData()
      state.searchSeniorShow = false
    }
    const loadData = () => {
      state.searchLoading = true
      page({
        ...state.searchForm,
        current: state.pagination.current,
        size: state.pagination.pageSize
      })
        .then((res) => {
          if (res.code === 10000) {
            state.listData = res.data.records
            state.pagination.current = res.data.current
            state.pagination.pageSize = res.data.size
            state.pagination.total = res.data.total
          }
        })
        .catch((err) => {
          console.log('分页错误', err)
        })
        .finally(() => {
          state.searchLoading = false
        })
      addTotalMethod()
    }
    // 表格数据页码改变事件
    const handleTableChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
      loadData()
    }
    const onSelectChange = (selectedRowKeys) => {
      console.log(selectedRowKeys)
      state.selectedRowKeys = selectedRowKeys
      if (selectedRowKeys.length > 0) {
        state.searchReceive = false
      } else {
        state.searchReceive = true
      }
    }
    // 导出
    const onExport = (e) => {
      message.success('研发中')
    }
    // 客户类型选择
    const customerTypeChange = (e, v) => {
      state.addForm.customerType = v
      if (state.addForm.customerType.value === 2) {
        state.rules.name.message = '请填写企业名称'
        state.rules.certificateNumber[1].pattern = enterpriseValidate
        state.rules.certificateNumber[1].message = '请输入正确的企业信用代码'
      } else {
        state.rules.name.message = '请填写客户姓名'
        state.rules.certificateNumber[1].pattern = idNumberValidate
        state.rules.certificateNumber[1].message = '请输入正确的身份证号'
      }
    }
    // 新增
    const onAddForm = () => {
      if (state.addForm.mobile !== '' || state.addForm.wechat !== '') {
        formRef.value
          .validate()
          .then(() => {
            const channelArr = state.addForm.addChannels
            if (
              channelArr !== undefined &&
              channelArr !== null &&
              channelArr.length > 1
            ) {
              state.addForm.channelId = channelArr[0]
              state.addForm.channelSubId = channelArr[1]
            }
            delete state.addForm.addChannels
            add({ customer: state.addForm }).then((res) => {
              if (res.code === 10000) {
                message.success(res.msg)
                loadData()
                state.searchAddShow = false
                state.addForm = JSON.parse(state.mirrorAddForm)
              }
            })
          }).catch((err) => {
            console.log(err)
          })
      } else message.error('微信号和电话必须填写其中一个')
    }
    // 新增-企业客户列表获取
    const entpSearch = async (val) => {
      if (val.length > 3) {
        const filterVal = val
          .replace(new RegExp('[a-z]', 'ig'), '')
          .replace(new RegExp('[0-9]', 'g'), '')
        if (filterVal.length < 4) {
          return
        }
        if (state.timeOut !== null) {
          clearTimeout(state.timeOut)
        }
        state.timeOut = setTimeout(() => {
          enterprise({ name: val }).then((res) => {
            if (res.code === 10000) {
              state.enterprises = res.data
              state.timeOut = null
            } else {
              state.enterprises = []
            }
          })
        }, 500)
      } else {
        state.enterprises = []
      }
    }
    const addEntpSearch = val => {
      if (val.length > 1) {
        if (state.timeOut !== null) {
          clearTimeout(state.timeOut)
        }
        state.timeOut = setTimeout(() => {
          pageAll({ name: val }).then((res) => {
            if (res.code === 10000) {
              state.enterprises = res.data.records
              state.timeOut = null
            } else {
              state.enterprises = []
            }
          })
        }, 500)
      } else {
        state.enterprises = []
      }
    }
    const customerSourceTypeChange = () => {
      state.addForm.sourcePerson = null
      state.addForm.sourcePersonId = null
      state.addForm.addChannels = []
      state.addForm.channelId = ''
      state.addForm.channelSubId = ''
    }
    const entpSelect = async (val, option) => {
      state.addForm.name = val
      state.addForm.certificateNumber = option.key
    }

    // 详情
    const onDetail = (e) => {
      state.detailShow = true
      state.customerId = e.customerId
    }
    // 渠道选择后赋值
    const setChannel = (e, selectedOptions) => {
      state.searchForm.channelId = ''
      state.searchForm.channelSubId = ''
      state.searchForm.channelId = e[0]
      if (e.length > 1) {
        state.searchForm.channelSubId = e[1]
      }
      if (
        state.searchForm.channelId !== '' &&
        state.searchForm.channelId !== null &&
        state.searchForm.channelId !== undefined
      ) {
        if (
          state.searchForm.channelSubId === '' ||
          state.searchForm.channelSubId === undefined
        ) {
          if (state.flag2 === false) {
            state.quantity++
            state.flag2 = true
          }
        }
      } else {
        state.quantity--
        state.flag2 = false
      }
    }
    // 每日剩余数量
    const addTotalMethod = () => {
      addTotal().then((res) => {
        if (res.code === 10000) {
          state.addTotalNum = res.data
          state.addTotalNum.highSeasSurplusNum =
            state.addTotalNum.highSeasTotalNum - state.addTotalNum.highSeasNum
        }
      })
    }
    const timeChange = (e, v) => {
      state.searchForm.startTime = v[0]
      state.searchForm.endTime = v[1]
      if (
        state.searchForm.startTime !== undefined &&
        state.searchForm.startTime !== '' &&
        state.searchForm.endTime !== undefined &&
        state.searchForm.endTime !== ''
      ) {
        if (state.flag1 === false) {
          state.quantity++
          state.flag1 = true
        }
      } else {
        state.quantity--
        state.flag1 = false
      }
    }
    const startChange = (e, v) => {
      state.searchForm.startTime = v
    }
    const endChange = (e, v) => {
      state.searchForm.endTime = v
    }
    const receiveStartChange = (e, v) => {
      state.searchForm.receiveStartTime = v
    }
    const receiveEndChange = (e, v) => {
      state.searchForm.receiveEndTime = v
    }
    const closeDrawer = () => {
      state.detailShow = false
      loadData()
    }
    onMounted(() => {
      loadData()
      state.mirrorAddForm = JSON.stringify(state.addForm)
      setTimeout(() => {
        state.customerSource = store.state.enumAll.customerSource
      }, 200)
      state.CustomerTypeEnum = JSON.parse(
        JSON.stringify(store.state.enumAll.CustomerTypeEnum)
      )
      state.CustomerSettlementEnum = JSON.parse(
        JSON.stringify(store.state.enumAll.CustomerSettlementEnum)
      )
      if (state.CustomerTypeEnum[0].value === '') state.CustomerTypeEnum.splice(0, 1)
      if (state.CustomerSettlementEnum[0].value === 0) { state.CustomerSettlementEnum.splice(0, 1) }
    })
    // 筛选数量叠加事件--电话输入框
    const saleNameChange = (e, v) => {
      if (
        state.searchForm.saleName !== '' &&
        state.searchForm.saleName !== null &&
        state.searchForm.saleName !== undefined
      ) {
        if (state.flag4 === false) state.quantity++
        state.flag4 = true
      } else {
        if (state.flag4 === true && state.searchForm.saleName.length === 0) {
          state.quantity--
          state.flag4 = false
        }
      }
    }
    // 员工搜索
    const employerValue = (e) => {
      if (e === '' || e === null) clearTimeout(state.timer), (state.fetching = false)
      state.flagLoading = true
      if (state.timer !== null) {
        clearTimeout(state.timer)
      }
      state.timer = setTimeout(() => {
        employeeList({
          current: 1,
          name: e,
          size: 10
        })
          .then((res) => {
            if (res.code === 10000) {
              state.employeeOptions = res.data
              state.flagLoading = false
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }, 1000)
    }
    // 选择员工
    const employeeChoose = (item) => {
      state.transferForm.empAfterName = item.name
      state.transferForm.empAfterId = item.id
      state.employeeOptions = []
    }
    const addEmployeeChoose = item => {
      state.addForm.sourcePerson = item.name
      state.addForm.sourcePersonId = item.id
    }
    const enterpriseChoose = item => {
      state.addForm.introduceCustomerName = item.name
      state.addForm.introduceCustomerId = item.id
    }
    // 确认移交
    const confirmTransfer = () => {
      state.transferLoading = true
      transferCustom({
        ...state.transferForm,
        customerClass: 2,
        customerIds: state.selectedRowKeys
      }).then((res) => {
        if (res.code === 10000) {
          message.success(res.msg)
          loadData()
          state.customTransferShow = false
          state.transferForm.empAfterName = ''
          state.transferForm.empAfterId = ''
          state.selectedRowKeys = []
        }
      })
        .finally(() => {
          state.transferLoading = false
        })
    }
    const followStartTimeChange = (e, v) => {
      state.searchForm.followStartTime = v
    }
    const followEndTimeChange = (e, v) => {
      state.searchForm.followEndTime = v
    }
    return {
      ...toRefs(state),
      onSearch,
      formRef,
      saleNameChange,
      reset,
      confirmTransfer,
      employerValue,
      employeeChoose,
      receiveChange,
      entpSearch,
      entpSelect,
      loadData,
      addEntpSearch,
      addEmployeeChoose,
      followStartTimeChange,
      followEndTimeChange,
      handleTableChange,
      customerTypeChange,
      enterpriseChoose,
      onSelectChange,
      onSearchSenior,
      onExport,
      onAddForm,
      setChannel,
      onDetail,
      addTotalMethod,
      timeChange,
      closeDrawer,
      startChange,
      endChange,
      customerSourceTypeChange,
      receiveStartChange,
      receiveEndChange
    }
  }
}
</script>

<style lang="less" scoped>
@import "./index.less";
@import "../tool/index.less";
</style>
